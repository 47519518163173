import { Component, OnInit, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import { AuthService } from '../../../shared/services/auth.service';
import { Router } from '@angular/router';
import { UtilService } from '../../../shared/services/util.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { UserLoginResponse } from '../../../shared/services/responses/auth/user-login.responde';
import { BusinessTypeEnum } from '../../../shared/enum/business-type.enum';
import {MatIconModule} from '@angular/material/icon';
import { AlertService, AlertType } from '../../../shared/services/alert.service';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [    
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatIconModule
  ],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.css'
})
export class AuthComponent implements OnInit {
  
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private alertService: AlertService,
    private utilService: UtilService
  ){}

  public model: FormGroup;
  public isLoading:boolean = false;
  private ip: string = '192.168.0.2'
  ;
  hide = signal(true);
  clickEvent(event: MouseEvent) {
    this.hide.set(!this.hide());
    event.stopPropagation();
  }
  
  ngOnInit(): void {
    this.model = this.formBuilder.group({
      login: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });

    setTimeout(() => { this.getIP(); }, 2000);
  }

  singin() {
    if (this.model.invalid  || this.isLoading)
      return;

    this.isLoading = true;

    this.authService.signIn(this.model.get('login')?.value, this.model.get('password')?.value, this.ip).subscribe({
      next: (response:UserLoginResponse) => {
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          this.isLoading = false;
          return;
        }
  
        // if (response.isFirstAccess) {
        //   this.openModal(this.model.get('login').value);
        //   this.isLoading = false;
        //   return;
        // }
          
        this.authService.updateToken(response);
        this.isLoading = false;
        
        if(response.idBusinessType == BusinessTypeEnum.Consultor)
          this.router.navigate(['/consult/home'])
        else if(response.idBusinessType == BusinessTypeEnum.Confeccao)
          this.router.navigate(['/factory/home'])
        else if(response.idBusinessType == BusinessTypeEnum.Lojista)
          this.router.navigate(['/sales/home'])
        
      },
      error: (error) => {
        this.alertService.show("Erro inesperado", error, AlertType.error);
        this.isLoading = false;
      }
    });
  }

  getIP() {
    this.utilService.getIP().subscribe({
      next: (response) => {
        if (response && response.ip) {
          this.ip = response.ip;
        }
      }
    });
  }

  redirectRevoceryPassword(){
    this.router.navigate(['/password-recovery'])
  }


}
