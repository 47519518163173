import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { UserLoginResponse } from '../../../services/responses/auth/user-login.responde';
import { MatButtonModule } from '@angular/material/button';
import { BusinessConsultorService } from '../../../services/API/business-user/business-consultor.service';
import { FactoryBusinessService } from '../../../services/API/business-user/factory-business.service';
import { InviteFactoryConsultorConnectionStruct } from '../../../services/structs/business-user/invite-factory-consultor-connection.struct';
import { InviteFactoryConsultorConnectionResponse } from '../../../services/responses/business-user/invite-factory-consultor-connection.response';
import { AlertService, AlertType } from '../../../services/alert.service';
import { BusinessTypeEnum } from '../../../enum/business-type.enum';
import { EmptyListComponent } from '../../empty-list/empty-list.component';
import { LoadingBoxComponent } from '../../loading-box/loading-box.component';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ResponseToInviteFactoryConsultorConnectionRequest } from '../../../services/requests/business-user/response-to-invite-factory-consultor-connection.request';
import { ReturnStruct } from '../../../services/structs/return.struct';

@Component({
  selector: 'app-action-invites',
  standalone: true,
  imports: [
    MatButtonModule,
    EmptyListComponent,
    LoadingBoxComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatIconModule,
    RouterLink,   
    
  ],
  templateUrl: './action-invites.component.html',
  styleUrl: './action-invites.component.css'
})
export class ActionInvitesComponent implements OnInit{
  constructor(
    private authService: AuthService,
    private businessConsultorService: BusinessConsultorService,
    private factoryBusinessService: FactoryBusinessService,
    private alertService: AlertService,
    public dialog: MatDialog,){
  }

  @Input() showActionInvite:boolean;
  @Input() areThereNotifications:boolean;

  @Output() showActionInviteFalse =  new EventEmitter<number>()
  @Output() setAreThereNotifications =  new EventEmitter<boolean>()

  public userLoginResponse:UserLoginResponse;
  public _listInviteFactoryConsultorConnectionStruct: InviteFactoryConsultorConnectionStruct[] = [];
  public _arrayLoadingSearch: number[] = [1, 1, 1, 1, 1];

  ngOnInit(): void {
    this.userLoginResponse = this.authService.getUserInfos();

    if (BusinessTypeEnum.Confeccao == this.userLoginResponse.idBusinessType)
      this.getAllInvitesReceivedByFactory();
    else
      this.getAllInvitesReceivedByConsultor();
  }

  verifyAreThereNotifications(): void {
    if ( this._listInviteFactoryConsultorConnectionStruct &&  this._listInviteFactoryConsultorConnectionStruct.length > 0)
      this.setAreThereNotifications.emit(true);
    else
      this.setAreThereNotifications.emit(false);
  }

  getAllInvitesReceivedByConsultor(): void{
    this.businessConsultorService.getAllInvitesReceivedByConsultor().subscribe({
      next:(response:InviteFactoryConsultorConnectionResponse)=>{
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this.populateListInvites(response.listInviteFactoryConsultorConnectionStruct);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  getAllInvitesReceivedByFactory(): void{
    this.factoryBusinessService.getAllInvitesReceivedByFactory().subscribe({
      next:(response:InviteFactoryConsultorConnectionResponse)=>{
        if (response.isError) {
          this.alertService.show("Erro inesperado", response.errorDescription, AlertType.error);
          return;
        }

        this.populateListInvites(response.listInviteFactoryConsultorConnectionStruct);
      },
      error: (error)=>{
        this.alertService.show("Erro inesperado", error, AlertType.error);
      }
    });
  }

  populateListInvites(list: InviteFactoryConsultorConnectionStruct[]): void {
    this._listInviteFactoryConsultorConnectionStruct = [];

    if (list && list.length > 0)
      this._listInviteFactoryConsultorConnectionStruct = [...list];

    this.verifyAreThereNotifications();
  }  


  clickContainerOut(){
    this.showActionInviteFalse.emit();
  }

  clickLogout(){
    this.authService.logout();
  }

  openModalToConfirmTheConnectionResponse(isAccepted: boolean, item: InviteFactoryConsultorConnectionStruct): void {

    const dialogRef = this.dialog.open(ConfirmModalComponent, {
      data: {
        title: "Alerta",
        description: "Deseja confirmar a opção escolhida? Essa ação será irreversível e um e-mail informando a escolha será enviado ao consultor.",
        buttonConfirm: "Confirmar",
        buttonClose: "Cancelar"
      },
    });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result && result.confirm) {
          this.sendResponseToInvite(isAccepted, item.idFactoryConsultor);         
        }
      }
    });
  }

  removeItemFromListInviteFactoryConsultorConnectionStruct(idFactoryConsultor: number): void {
    this._listInviteFactoryConsultorConnectionStruct = this._listInviteFactoryConsultorConnectionStruct.filter(
      item => item.idFactoryConsultor !== idFactoryConsultor
    );

    this.verifyAreThereNotifications();
  }

  sendResponseToInvite(isAccepted: boolean, idFactoryConsultor: number): void {
    let request: ResponseToInviteFactoryConsultorConnectionRequest = new ResponseToInviteFactoryConsultorConnectionRequest();
    request.isAccepted = isAccepted;
    request.idFactoryConsultor = idFactoryConsultor;

    if (BusinessTypeEnum.Confeccao == this.userLoginResponse.idBusinessType)
      this.sendResponseConecctionFactoryConsultorForFactory(request);
    else
      this.sendResponseConecctionFactoryConsultorForConsultor(request);
  }

  sendResponseConecctionFactoryConsultorForConsultor(request: ResponseToInviteFactoryConsultorConnectionRequest): void {
    this.businessConsultorService.sendConsultorResponseToInviteFactoryConsultor(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }

        this.removeItemFromListInviteFactoryConsultorConnectionStruct(request.idFactoryConsultor);
        this.alertService.show("Sucesso", "Respondido com sucesso!", AlertType.success);

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }

  sendResponseConecctionFactoryConsultorForFactory(request: ResponseToInviteFactoryConsultorConnectionRequest): void {
    this.factoryBusinessService.sendFactoryResponseToInviteFactoryConsultor(request).subscribe({
      next: (response: ReturnStruct) => {
        if (response.isError) {
          this.alertService.show('Erro', response.errorDescription, AlertType.error);
          return;
        }
     
        this.removeItemFromListInviteFactoryConsultorConnectionStruct(request.idFactoryConsultor);
        this.alertService.show("Sucesso", "Respondido com sucesso!", AlertType.success);

      },
      error: (error) => {
        this.alertService.show('Erro inesperado', error, AlertType.error);
        return;
      }
    });
  }
}
